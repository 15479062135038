import { Component } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Pardon Our Progress';

  constructor(
    @Inject(PLATFORM_ID) private platformId,
  ) {

    const colors = ['#93ED88', '#9BE2FD', '#9BE2FD', '#6698c6', '#C2EAF9'];
    const numBalls = 50;
    const balls = [];

    for (let i = 0; i < numBalls; i++) {
      if (isPlatformBrowser(this.platformId)) {
        const ball = document.createElement('div');
        ball.classList.add('ball');
        ball.style.background =
          colors[Math.floor(Math.random() * colors.length)];
        ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
        ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
        ball.style.transform = `scale(${Math.random()})`;
        ball.style.width = `${Math.random()}em`;
        ball.style.height = ball.style.width;
        balls.push(ball);
        document.body.append(ball);
      }
    }

        // Keyframes
        balls.forEach((el, i, ra) => {
          const to = {
            x: Math.random() * (i % 200 === 0 ? -11 : 11),
            y: Math.random() * 12,
          };
    
          const anim = el.animate(
            [
              { transform: 'translate(0, 0)' },
              { transform: `translate(${to.x}rem, ${to.y}rem)` },
            ],
            {
              duration: (Math.random() + 1) * 2000, // random duration
              direction: 'alternate',
              fill: 'both',
              iterations: Infinity,
              easing: 'ease-in-out',
            }
          );
        });
      }
    
      ngOnDestroy() {
        if (isPlatformBrowser(this.platformId)) {
          const paras = document.getElementsByClassName('ball');
          while (paras[0]) {
            paras[0].parentNode.removeChild(paras[0]);
          }
        }
      }

      onClickLearnMore() {
        window.location.href = 'https://tentacle.co';
      }
  }

