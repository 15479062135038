<div id="hero-wrapper" class="tentacle-hero-wrapper">
  <div class="message-items">
    <div style="contain:content;border-radius:8px;height:128px;width:128px;margin: 0 auto;">
      <svg width="128px" height="128px" viewBox="0 0 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="#3179D7" offset="0%"></stop>
            <stop stop-color="#12AADB" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
            <stop stop-color="#347AD6" offset="0%"></stop>
            <stop stop-color="#12AADB" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="45.0023726%" y1="35.5706657%" x2="61.8359904%" y2="67.8010942%" id="linearGradient-3">
            <stop stop-color="#61D5FA" offset="0%"></stop>
            <stop stop-color="#1CCEF7" offset="100%"></stop>
          </linearGradient>
          <path
            d="M1.72909225,47.7548775 C13.6849203,36.8530659 21.7850172,28.9361711 26.029383,24.0041931 C32.3959317,16.6062262 55.1104608,-1.80258032 78.9871988,0.143122383 C102.863937,2.08882508 107.572005,4.82880471 118.175075,12.0807186 C128.778145,19.3326325 125.856351,29.838231 144.167139,38.7965542 C162.477926,47.7548775 158.328984,57.2120224 156.198897,59.5228144 C154.06881,61.8336063 135.771187,88.2367489 100.416146,96.2040434 C65.0611055,104.171338 38.5157674,95.6757151 16.6645348,71.7152963 C8.43521673,63.0443516 3.47948954,61.0246517 1.06214767,55.2898749 C-0.549413573,51.4666903 -0.327098714,48.9550245 1.72909225,47.7548775 Z"
            id="path-4"></path>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-6">
            <stop stop-color="#539CFC" offset="0%"></stop>
            <stop stop-color="#12AADB" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-7">
            <stop stop-color="#539CFC" offset="0%"></stop>
            <stop stop-color="#12AADB" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-8">
            <stop stop-color="#317DE1" offset="0%"></stop>
            <stop stop-color="#12AADB" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect id="Rectangle" fill="#132D5B" x="0" y="0" width="256" height="256"></rect>
          <path
            d="M70.9424798,146.262421 L72.0201419,168.352295 C72.3248618,178.784098 79.7713635,184 94.3596469,184 C116.242072,184 151.569476,177.35387 156.958512,171.976527 C160.551204,168.391632 131.879193,159.820264 70.9424798,146.262421 Z"
            id="Path-47-Copy" fill="url(#linearGradient-1)"></path>
          <path
            d="M72.0201419,114.798523 C72.0201419,96.8589113 72.0201419,86.1558423 72.0201419,82.6893154 C72.0201419,77.4895251 72.0201419,67 85.3853927,67 C98.7506435,67 146.25456,69.0334717 168.663826,86.9533985 C183.603337,98.9000163 158.927565,105.948384 94.6365109,108.098503 L72.0201419,114.798523 Z"
            id="Path-45-Copy" fill="url(#linearGradient-2)"></path>
          <g id="Path-48" transform="translate(55.839709, 77.489525)">
            <mask id="mask-5" fill="white">
              <use xlink:href="#path-4"></use>
            </mask>
            <use id="Mask" fill="url(#linearGradient-3)" xlink:href="#path-4"></use>
            <path
              d="M45.3412942,94.8719259 C60.8123366,76.8576493 67.9035691,61.75204 66.6149916,49.555098 C64.6821255,31.2596851 60.1426386,11.8383242 45.3412942,-6.2760166"
              stroke="url(#linearGradient-6)" stroke-width="12.051" opacity="0.7" mask="url(#mask-5)"></path>
            <path
              d="M14.7304305,94.8719259 C30.2014729,76.8576493 37.2927054,61.75204 36.0041279,49.555098 C34.0712618,31.2596851 29.5317749,11.8383242 14.7304305,-6.2760166"
              id="Path-48-Copy" stroke="url(#linearGradient-6)" stroke-width="12.051" opacity="0.8" mask="url(#mask-5)">
            </path>
            <path
              d="M67.789261,108.281565 C89.1591154,81.327529 99.1997539,61.75204 97.9111765,49.555098 C95.9783103,31.2596851 91.4388234,11.8383242 76.6374791,-6.2760166"
              id="Path-48-Copy-2" stroke="url(#linearGradient-7)" stroke-width="12.051" opacity="0.5"
              mask="url(#mask-5)"></path>
            <path
              d="M96.3594004,108.281565 C117.729255,81.327529 127.769893,61.75204 126.481316,49.555098 C124.54845,31.2596851 120.008963,11.8383242 105.207619,-6.2760166"
              id="Path-48-Copy-3" stroke="url(#linearGradient-7)" stroke-width="12.051" opacity="0.3"
              mask="url(#mask-5)"></path>
          </g>
          <path
            d="M65.717672,122.035883 C57.8942672,112.71792 52.3516593,107.444554 49.0898484,106.215785 C44.197132,104.372631 42.0047474,102.834396 42.0047474,112.250714 C42.0047474,118.528259 42.0047474,131.445341 42.0047474,151.001959 C41.8792745,156.459112 44.2409748,157.566005 49.0898484,154.322637 C56.3631587,149.457585 65.717672,141.350301 65.717672,139.589687 C65.717672,138.415945 65.717672,132.564677 65.717672,122.035883 Z"
            id="Path-46-Copy" fill="url(#linearGradient-8)"></path>
          <ellipse id="Oval" fill="#207DC2" cx="176.6878" cy="116.807231" rx="4.0814485" ry="3.83132549"></ellipse>
        </g>
      </svg>
    </div>
    <div class="name">
      Big Fish Inc
    </div>
    <div class="message">
      A fictional organization created to demonstrate the power of sharing real time Info-Sec Program information using the Tentacle Info-Sec Platform.  
    </div>
    <button class="cta" (click)="onClickLearnMore()">
      Learn More at Tentacle.co 
    </button>
  </div>
</div>